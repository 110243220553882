import { singularSdk } from 'singular-sdk'

export type LinkName = 'app' | 'shelfie'
export interface LinkConfig {
  base?: string
  deeplinkValue?: string
  passthroughValue?: string
  deferredValue?: string
}

const baseLink = 'https://fable.sng.link/Ali7l/3i0m'
const fallbackLink = `${baseLink}?_dl=app`

const linkMap: Record<LinkName, LinkConfig> = {
  app: {
    deeplinkValue: 'app',
  },
  shelfie: {
    base: 'https://fable.sng.link/Ali7l/26r5',
    deeplinkValue: '/app/shelfie',
  },
}

export const getDeeplink = (name: LinkName) => {
  const link = singularSdk.buildWebToAppLink(baseLink, name)
  return link || fallbackLink
}

export const openDeeplink = (name: LinkName) => {
  const link = linkMap[name]

  return singularSdk.openApp(
    link?.base || baseLink,
    link.deeplinkValue || '',
    link.passthroughValue || '',
    link.deferredValue || ''
  )
}
