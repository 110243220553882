import { IconSVGProps } from '../types'

export const RepeatIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99935 2.66669H13.9993C14.1762 2.66669 14.3457 2.73692 14.4708 2.86195C14.5958 2.98697 14.666 3.15654 14.666 3.33335V8.00002H13.3327V4.00002H3.99935V6.00002L0.666016 3.33335L3.99935 0.666687V2.66669ZM11.9993 13.3334H1.99935C1.82254 13.3334 1.65297 13.2631 1.52794 13.1381C1.40292 13.0131 1.33268 12.8435 1.33268 12.6667V8.00002H2.66602V12H11.9993V10L15.3327 12.6667L11.9993 15.3334V13.3334Z"
      fill="#4D0C98"
    />
  </svg>
)
