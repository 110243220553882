import { useCallback, useEffect, useRef, useState } from 'react'
import { isAndroid, sendToNative } from 'app/webview/utils'
import { dataURItoFile } from 'app/webview/pages/shelfie/lib/utils'

interface ShareData {
  title?: string
  text?: string
  url?: string
  files?: File[]
}

interface ShareImage {
  name?: string
  image: string
}

export interface ShareImagePayload {
  name?: string
  images: ShareImage[]
}

interface ShareResponse {
  success: boolean
}

interface UseWebShareResult {
  isSupported: boolean
  share: (data: ShareData) => Promise<ShareResponse>
  shareImages: (props: ShareImagePayload) => Promise<ShareResponse>
  error: string | null
}

export const useWebShare = (): UseWebShareResult => {
  const [isSupported] = useState<boolean>(!!navigator.share)
  const [error, setError] = useState<string | null>(null)
  const supportErrorMsg = useRef(false)
  const notSupportedMsg = 'Web Share API is not supported in this browser.'

  const showSupportedMessage = useCallback(() => {
    if (!isSupported && !supportErrorMsg.current) {
      console.warn(notSupportedMsg)
      supportErrorMsg.current = true
    }
  }, [isSupported])

  useEffect(() => {
    showSupportedMessage()
  }, [showSupportedMessage])

  const share = async (data: ShareData): Promise<{ success: boolean }> => {
    if (!navigator.share) {
      console.error(notSupportedMsg)
      return { success: false }
    }

    try {
      if (data.files && !navigator.canShare?.({ files: data.files })) {
        throw new Error('File sharing is not supported on this device.')
      }

      await navigator.share(data)
      setError(null)
      return { success: true }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : 'An error occurred while sharing.'
      setError(errorMessage)
      console.error('Error sharing content:', errorMessage)
      return { success: false }
    }
  }

  const shareImages = async ({ images }: { images: ShareImage[] }) => {
    if (!images.length) return { success: false }

    if (isAndroid) {
      sendToNative({
        id: 'send_image_data',
        payload: {
          type: 'image_download',
          body: {
            dataUrls: images.map(({ image }) => image),
          },
        },
      })
      return { success: true }
    }

    const files = images.map(({ image, name }) =>
      dataURItoFile(image, name || 'fable-image.webp')
    )

    return await share({ files })
  }

  return { isSupported, share, error, shareImages }
}
