import { isAndroid } from './url'

export const sendToNative = ({
  id,
  payload,
}: {
  id?: string
  payload: Record<string, unknown>
}) => {
  const eventId = id || 'web_to_native'
  const device = isAndroid ? 'android' : 'ios'
  const handler = window.webkit?.messageHandlers?.fablenative
  const namespace = `[${device}][${eventId}]`
  const payloadString = JSON.stringify(payload)

  console.info(`${namespace} sending payload to native:\n${payloadString}`)

  if (!handler) {
    console.error(`${namespace} fablenative handler not found`)
    return
  }

  let msgPayload: Record<string, unknown> | string

  if (isAndroid) {
    msgPayload = payloadString
  } else {
    msgPayload = payload
  }

  handler.postMessage(msgPayload)

  console.info(`${namespace} payload sent`)
}

export const closeWebView = () => {
  sendToNative({
    payload: {
      type: 'web_event',
      body: {
        action: 'close_web_view',
      },
    },
  })
}
