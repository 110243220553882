import { Trinket, TrinketKey } from '../types'
import { getBucketImage } from 'app/webview/utils'

export const trinketsFallback: TrinketKey[] = [
  'iced_coffee',
  'clock',
  'plant',
  'himalayan_salt_lamp',
]

const getImage = ({
  name,
  type = 'chatgpt_objects',
}: {
  name: string
  type?: string
}) =>
  getBucketImage(`/shelfie/trinkets/${type}/${name}.webp?mode=cors`, {
    source: 'cdn',
  })

export const trinketList: Trinket[] = [
  {
    id: 'crocs',
    name: 'Crocs',
    image: getImage({ name: 'crocs', type: 'pop_culture_objects' }),
  },
  {
    id: 'doritos',
    name: 'Doritos',
    image: getImage({ name: 'doritos', type: 'pop_culture_objects' }),
  },
  {
    id: 'erewhon_smoothie',
    name: 'Erewhon Smoothie',
    image: getImage({ name: 'erewhon_smoothie', type: 'pop_culture_objects' }),
  },
  {
    id: 'miffy',
    name: 'Miffy',
    image: getImage({ name: 'miffy', type: 'pop_culture_objects' }),
  },
  {
    id: 'moo_deng_hippo',
    name: 'Moo Deng Hippo',
    image: getImage({ name: 'moo_deng_hippo', type: 'pop_culture_objects' }),
  },
  {
    id: 'north_face_puffer_slides',
    name: 'North Face Puffer Slides',
    image: getImage({
      name: 'north_face_puffer_slides',
      type: 'pop_culture_objects',
    }),
  },
  {
    id: 'pepto_bismol',
    name: 'Pepto Bismol',
    image: getImage({ name: 'pepto_bismol', type: 'pop_culture_objects' }),
  },
  {
    id: 'sonny_angel_figurine',
    name: 'Sonny Angel Figurine',
    image: getImage({
      name: 'sonny_angel_figurine',
      type: 'pop_culture_objects',
    }),
  },
  {
    id: 'stanley_water_bottle',
    name: 'Stanley Water Bottle',
    image: getImage({
      name: 'stanley_water_bottle',
      type: 'pop_culture_objects',
    }),
  },
  {
    id: 'sunscreen',
    name: 'Sunscreen',
    image: getImage({ name: 'sunscreen', type: 'pop_culture_objects' }),
  },
  {
    id: 'alien_plushie',
    name: 'Alien Plushie',
    image: getImage({ name: 'alien_plushie' }),
  },
  {
    id: 'apple_watch',
    name: 'Apple Watch',
    image: getImage({ name: 'apple_watch' }),
  },
  {
    id: 'astronaut_helmet',
    name: 'Astronaut Helmet',
    image: getImage({ name: 'astronaut_helmet' }),
  },
  {
    id: 'bankers_lamp',
    name: 'Bankers Lamp',
    image: getImage({ name: 'bankers_lamp' }),
  },
  {
    id: 'binoculars',
    name: 'Binoculars',
    image: getImage({ name: 'binoculars' }),
  },
  {
    id: 'briefcase',
    name: 'Briefcase',
    image: getImage({ name: 'briefcase' }),
  },
  {
    id: 'camping_mug',
    name: 'Camping Mug',
    image: getImage({ name: 'camping_mug' }),
  },
  {
    id: 'candelabra',
    name: 'Candelabra',
    image: getImage({ name: 'candelabra' }),
  },
  {
    id: 'chemistry_beaker',
    name: 'Chemistry Beaker',
    image: getImage({ name: 'chemistry_beaker' }),
  },
  {
    id: 'clock',
    name: 'Clock',
    image: getImage({ name: 'clock' }),
  },
  {
    id: 'cowboy_boots',
    name: 'Cowboy Boots',
    image: getImage({ name: 'cowboy_boots' }),
  },
  {
    id: 'crown',
    name: 'Crown',
    image: getImage({ name: 'crown' }),
  },
  {
    id: 'crystal_ball',
    name: 'Crystal Ball',
    image: getImage({ name: 'crystal_ball' }),
  },
  {
    id: 'cup_of_tea',
    name: 'Cup of Tea',
    image: getImage({ name: 'cup_of_tea' }),
  },
  {
    id: 'do_not_disturb_sign',
    name: 'Do Not Disturb Sign',
    image: getImage({ name: 'do_not_disturb_sign' }),
  },
  {
    id: 'dragon',
    name: 'Dragon',
    image: getImage({ name: 'dragon' }),
  },
  {
    id: 'dutch_oven',
    name: 'Dutch Oven',
    image: getImage({ name: 'dutch_oven' }),
  },
  {
    id: 'earth_globe',
    name: 'Earth Globe',
    image: getImage({ name: 'earth_globe' }),
  },
  {
    id: 'ghost_candle',
    name: 'Ghost Candle',
    image: getImage({ name: 'ghost_candle' }),
  },
  {
    id: 'grecian_bust',
    name: 'Grecian Bust',
    image: getImage({ name: 'grecian_bust' }),
  },
  {
    id: 'heart_candle',
    name: 'Heart Candle',
    image: getImage({ name: 'heart_candle' }),
  },
  {
    id: 'hiking_shoes',
    name: 'Hiking Shoes',
    image: getImage({ name: 'hiking_shoes' }),
  },
  {
    id: 'himalayan_salt_lamp',
    name: 'Himalayan Salt Lamp',
    image: getImage({ name: 'himalayan_salt_lamp' }),
  },
  {
    id: 'horror_mask',
    name: 'Horror Mask',
    image: getImage({ name: 'horror_mask' }),
  },
  {
    id: 'iced_coffee',
    name: 'Iced Coffee',
    image: getImage({ name: 'iced_coffee' }),
  },
  {
    id: 'jujutsu_kaisen_figurine',
    name: 'Jujutsu Kaisen Figurine',
    image: getImage({ name: 'jujutsu_kaisen_figurine' }),
  },
  {
    id: 'kikis_delivery_service_plushie',
    name: 'Kikis Delivery Service Plushie',
    image: getImage({ name: 'kikis_delivery_service_plushie' }),
  },
  {
    id: 'luggage',
    name: 'Luggage',
    image: getImage({ name: 'luggage' }),
  },
  {
    id: 'magnifying_glass',
    name: 'Magnifying Glass',
    image: getImage({ name: 'magnifying_glass' }),
  },
  {
    id: 'microscope',
    name: 'Microscope',
    image: getImage({ name: 'microscope' }),
  },
  {
    id: 'model_eighteenth_century_ship',
    name: 'Model Eighteenth Century Ship',
    image: getImage({ name: 'model_18th_century_ship' }),
  },
  {
    id: 'mushrooms',
    name: 'Mushrooms',
    image: getImage({ name: 'mushrooms' }),
  },
  {
    id: 'notepad',
    name: 'Notepad',
    image: getImage({ name: 'notepad' }),
  },
  {
    id: 'oil_lamp',
    name: 'Oil Lamp',
    image: getImage({ name: 'oil_lamp' }),
  },
  {
    id: 'origami_crane',
    name: 'Origami Crane',
    image: getImage({ name: 'origami_crane' }),
  },
  {
    id: 'ouija_board',
    name: 'Ouija Board',
    image: getImage({ name: 'ouija_board' }),
  },
  {
    id: 'oven_mitt',
    name: 'Oven Mitt',
    image: getImage({ name: 'oven_mitt' }),
  },
  {
    id: 'plant',
    name: 'Plant',
    image: getImage({ name: 'plant' }),
  },
  {
    id: 'pocket_watch',
    name: 'Pocket Watch',
    image: getImage({ name: 'pocket_watch' }),
  },
  {
    id: 'polaroid_camera',
    name: 'Polaroid Camera',
    image: getImage({ name: 'polaroid_camera' }),
  },
  {
    id: 'potion',
    name: 'Potion',
    image: getImage({ name: 'potion' }),
  },
  {
    id: 'quill',
    name: 'Quill',
    image: getImage({ name: 'quill' }),
  },
  {
    id: 'radio',
    name: 'Radio',
    image: getImage({ name: 'radio' }),
  },
  {
    id: 'skull',
    name: 'Skull',
    image: getImage({ name: 'skull' }),
  },
  {
    id: 'smoothie',
    name: 'Smoothie',
    image: getImage({ name: 'smoothie' }),
  },
  {
    id: 'spirited_aweay_plushie',
    name: 'Spirited Aweay Plushie',
    image: getImage({ name: 'spirited_away_plushie' }),
  },
  {
    id: 'succulent',
    name: 'Succulent',
    image: getImage({ name: 'succulent' }),
  },
  {
    id: 'tarot_card_deck',
    name: 'Tarot Card Deck',
    image: getImage({ name: 'tarot_card_deck' }),
  },
  {
    id: 'taxi',
    name: 'Taxi',
    image: getImage({ name: 'taxi' }),
  },
  {
    id: 'teddy_bear',
    name: 'Teddy Bear',
    image: getImage({ name: 'teddy_bear' }),
  },
  {
    id: 'tissue_box',
    name: 'Tissue Box',
    image: getImage({ name: 'tissue_box' }),
  },
  {
    id: 'top_hat',
    name: 'Top Hat',
    image: getImage({ name: 'top_hat' }),
  },
  {
    id: 'trophy',
    name: 'Trophy',
    image: getImage({ name: 'trophy' }),
  },
  {
    id: 'victorian_corset',
    name: 'Victorian Corset',
    image: getImage({ name: 'victorian_corset' }),
  },
  {
    id: 'vintage_phone',
    name: 'Vintage Phone',
    image: getImage({ name: 'vintage_phone' }),
  },
  {
    id: 'witches_hat',
    name: 'Witches Hat',
    image: getImage({ name: 'witches_hat' }),
  },
]
