import { CompProps } from '@fable/types'
import { IconSVGProps } from '../types'

export const TrophyIcon = ({ className, fill }: CompProps<IconSVGProps>) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.834 14.115V15.8333H15.0006V17.5H5.00065V15.8333H9.16732V14.115C7.55623 13.9118 6.07467 13.1277 5.00065 11.9098C3.92664 10.6919 3.33401 9.12384 3.33398 7.5V2.5H16.6673V7.5C16.6673 9.12384 16.0747 10.6919 15.0006 11.9098C13.9266 13.1277 12.4451 13.9118 10.834 14.115ZM0.833984 4.16667H2.50065V7.5H0.833984V4.16667ZM17.5006 4.16667H19.1673V7.5H17.5006V4.16667Z" />
  </svg>
)
