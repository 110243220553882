import { Book, BookSeries } from '@fable/types'
import {
  ShelfieContentItem,
  ShelfieTemplate,
  ShelfieTextContent,
  TrinketKey,
} from '../types'
import { stripHtml } from '@fable/utils'
import { post } from '@fable/api'
import { trinketsFallback } from '../constants'

type PayloadItem = {
  title: string
  description: string
}

export const getShelfieAiTextContent = async ({
  userId,
  items,
}: {
  userId: string
  items: ShelfieContentItem[]
}): Promise<ShelfieTextContent | null> => {
  const payloadItems = formatItems(items)

  if (!payloadItems.length) return null

  try {
    const res = await post('/shelfie/text', {
      user_id: userId,
      items: payloadItems,
    })
    return res.data
  } catch (e) {
    console.error('Error fetching shelfie text content from prompt', e)
    return null
  }
}

export const getShelfieAiTrinkets = async ({
  userId,
  items,
}: {
  userId: string
  items: ShelfieContentItem[]
}): Promise<TrinketKey[]> => {
  const payloadItems = formatItems(items)

  if (!payloadItems.length) return trinketsFallback

  try {
    const res = await post('/shelfie/trinkets', {
      user_id: userId,
      items: payloadItems,
    })

    if (!res.data?.trinkets?.length) {
      return trinketsFallback
    }

    return res.data?.trinkets
  } catch (e) {
    console.error('Error fetching shelfie trinkets from prompt', e)
    return trinketsFallback
  }
}

const formatItems = (contentItems: ShelfieContentItem[]) => {
  const items: (PayloadItem | null)[] = contentItems
    .map((item) => {
      if (item.type === 'tv-show') {
        const series = item.content as BookSeries

        return {
          title: series.title,
          description: series.description,
        }
      }

      let description = ''

      const book = item.content as Book

      if (!!book.ai_summary?.length) {
        description = book.ai_summary
      } else {
        description = stripHtml(book.description || '')
      }

      if (!description.length) return null

      const payloadItem: PayloadItem = {
        title: book.title,
        description: description,
      }

      return payloadItem
    })
    .filter(Boolean)

  return items
}

export const isMissingTextContent = (template: ShelfieTemplate) => {
  return !template.title?.length || !template.summary?.length
}
