import { ShelfieContentItem, ShelfieGenre, ShelfieTemplate } from '../types'
import { templateGenreMap, templateList } from '../constants'
import { BookGenre } from '@fable/types'
import { getShelfieAiTextContent, getShelfieAiTrinkets } from './prompts'

export const getPopularGenre = (items: ShelfieContentItem[]) => {
  const allGenres = items.flatMap((item) => {
    const genres = (item.content as { genres?: BookGenre[] }).genres || []
    return genres ? genres.map((g) => g.name) : []
  })

  return calculatePopularGenre(allGenres)
}

export const calculatePopularGenre = (genres: string[]) => {
  if (!genres.length) return ''

  const genreCount = genres.reduce(
    (count: { [key: string]: number }, genre: string) => {
      count[genre] = (count[genre] || 0) + 1
      return count
    },
    {}
  )

  const popular = Object.keys(genreCount).reduce((a, b) =>
    genreCount[a] > genreCount[b] ? a : b
  )

  return popular || ''
}

export const findGenreKey = (name: string): ShelfieGenre => {
  for (const [key, genres] of Object.entries(templateGenreMap)) {
    if (genres.includes(name)) return key as ShelfieGenre
  }
  return 'generic'
}

export const getTemplate = async ({
  userId,
  items,
}: {
  userId: string
  items: ShelfieContentItem[]
}): Promise<{ template: ShelfieTemplate; genre: string }> => {
  const popularGenre = getPopularGenre(items)
  const genreKey = findGenreKey(popularGenre)
  const baseTemplate =
    templateList.find((t) => t.id === genreKey) || templateList[0]

  let title = ''
  let summary = ''

  const textContent = await getShelfieAiTextContent({ userId, items })

  if (textContent) {
    if (textContent.label?.length) title = textContent.label
    if (textContent.description?.length) summary = textContent.description
  }

  const trinkets = await getShelfieAiTrinkets({ userId, items })

  const newTemplate: ShelfieTemplate = {
    ...baseTemplate,
    title,
    summary,
    trinkets,
  }

  return { template: newTemplate, genre: popularGenre }
}

export const isMaxTitleChar = (title: string[], max: number) => {
  const first = title[0].length
  const second = title[1].length

  return first > max || second > max
}
