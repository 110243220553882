import { ShelfieGenre, ShelfieTemplate } from '../types'

export const templateList: ShelfieTemplate[] = [
  {
    id: 'generic',
    name: 'Generic',
    wallpaper: [
      'generic-wallpaper-one',
      'generic-wallpaper-two',
      'generic-wallpaper-three',
    ],
    titleFont: 'heldane',
    shelf: 'light-wood',
  },
  {
    id: 'nature',
    name: 'Nature',
    wallpaper: ['nature-wallpaper-one', 'nature-wallpaper-two'],
    titleFont: 'bokor',
    shelf: 'light-wood',
  },
  {
    id: 'historical',
    name: 'Historical',
    wallpaper: ['historical-wallpaper-one'],
    titleFont: 'quintessential',
    shelf: 'light-wood',
  },
  {
    id: 'sci-fi',
    name: 'Sci-Fi',
    wallpaper: ['sci-fi-wallpaper-one', 'sci-fi-wallpaper-two'],
    titleFont: 'faster-one',
    shelf: 'white',
  },
  {
    id: 'fantasy',
    name: 'Fantasy',
    wallpaper: ['fantasy-wallpaper-one', 'fantasy-wallpaper-two'],
    titleFont: 'cormorant-unicase',
    shelf: 'dark-wood',
  },
  {
    id: 'romance',
    name: 'Romance',
    wallpaper: ['romance-wallpaper-one', 'romance-wallpaper-two'],
    titleFont: 'sarina',
    shelf: 'dark-wood',
  },
  {
    id: 'young-adult',
    name: 'Young Adult',
    wallpaper: ['young-adult-wallpaper-one'],
    titleFont: 'bubblegum',
    shelf: 'white',
  },
  {
    id: 'gore',
    name: 'Gore',
    wallpaper: ['gore-wallpaper-one', 'gore-wallpaper-two'],
    titleFont: 'lacquer',
    shelf: 'black',
  },
  {
    id: 'dark',
    name: 'Dark',
    wallpaper: ['dark-wallpaper-one'],
    titleFont: 'lacquer',
    shelf: 'dark-wood',
  },
  {
    id: 'graphic-manga',
    name: 'Graphic & Manga',
    wallpaper: ['manga-wallpaper-one', 'manga-wallpaper-two'],
    titleFont: 'protest-revolution',
    shelf: 'black',
  },
]

export const templateGenreMap: Record<ShelfieGenre, string[]> = {
  generic: [
    'Literary Fiction',
    'Biography',
    'Short Stories',
    "Children's Fiction",
    'Art & Design',
    'Food',
    'Travel',
    'Humor',
    'Religion & Spirituality',
    'Politics',
    'Psychology',
    'Sports',
    'Wellness',
    'Personal Growth',
    'Business',
    'Science & Technology',
    'Education',
    'Animation',
    'Comedy',
    'Drama',
    'Family',
    'Kids',
    'Music',
    'Musical',
    'News',
    'Reality',
    'Soap',
    'Talk',
    'Western',
  ],
  nature: ['Nature & Environment'],
  historical: [
    'History',
    'Historical Fiction',
    'Classics',
    'Poetry',
    'Documentary',
    'War & Politics',
  ],
  'sci-fi': ['Sci-Fi', 'Sci-Fi & Fantasy'],
  fantasy: ['Fantasy'],
  romance: ['Romance'],
  'young-adult': ['Young Adult'],
  gore: ['Horror', 'True Crime', 'Crime'],
  dark: ['Mystery', 'Thriller', 'Thrillers', 'Action & Adventure', 'Mystery'],
  'graphic-manga': ['Graphic Novels', 'Manga'],
}
