import 'keen-slider/keen-slider.min.css'
import { cx, css } from '@fable/theme'
import { useKeenSlider } from 'keen-slider/react'
import React, { useEffect, useRef } from 'react'
import { CarouselProps } from './types'
import {
  getChildWidthStyles,
  initAllSlidesInView,
  isPerViewAuto,
} from './helpers'

export const Carousel: React.VFC<CarouselProps> = ({
  childWidths,
  children,
  options,
  plugins,
  className = '',
  controller,
  noOverflow = false,
  ...nativeProps
}) => {
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged(slider) {
        if (controller) {
          controller.setCurrentSlide(slider.track.details.rel)
        }
      },
      created(slider) {
        if (!controller) return
        initAllSlidesInView(controller, slider)
      },
      updated(slider) {
        if (!controller) return
        initAllSlidesInView(controller, slider)
      },
      range: {
        align: true,
      },
      ...options,
    },
    plugins
  )
  const loaded = useRef(false)
  const ksClassName = 'keen-slider'
  const ksChildClassName = 'keen-slider__slide'

  const overflowStyles = noOverflow
    ? css`
        overflow: visible !important;
      `
    : ''

  useEffect(() => {
    if (!instanceRef.current) return
    if (!loaded.current && controller) {
      controller.setInstance(instanceRef.current)
      loaded.current = true
    }
  }, [controller, instanceRef])

  const childWidthStyles =
    childWidths && isPerViewAuto(options)
      ? getChildWidthStyles(childWidths)
      : ''

  return (
    <div
      ref={sliderRef}
      className={cx(ksClassName, overflowStyles, className)}
      {...nativeProps}
    >
      {React.Children.map(children, (child) => (
        <div className={cx(ksChildClassName, overflowStyles, childWidthStyles)}>
          {child}
        </div>
      ))}
    </div>
  )
}
