import { requests } from '@fable/api'
import { UserContentType } from '../types'

export const getListId = async ({
  userId,
  type,
}: {
  userId: string
  type: UserContentType
}): Promise<string> => {
  try {
    if (type === 'tv-show') {
      const list = await requests.getSystemList({
        type: 'finished_watch',
        mediaType: 'watch',
        userId,
      })

      return list?.id || ''
    }

    const list = await requests.getSystemList({
      type: 'finished',
      userId,
    })

    return list?.id || ''
  } catch (e) {
    console.error('Error getting list id', e)
    return ''
  }
}
