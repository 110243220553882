import { IconSVGProps } from '../types'

export const ImageAddIcon = ({ className, fill }: IconSVGProps) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 15L21 18L24 18L24 20L21 20L21 23L19 23L19 20L16 20L16 18L19 18L19 15L21 15ZM21.008 3C21.556 3 22 3.445 22 3.993L22 13.342C21.3576 13.1151 20.6813 12.9994 20 13L20 5L4 5L4.001 19L13.293 9.707C13.465 9.53448 13.694 9.43073 13.9371 9.41526C14.1802 9.39979 14.4206 9.47367 14.613 9.623L14.706 9.708L18.252 13.258C17.4766 13.4943 16.7572 13.8851 16.1369 14.407C15.5167 14.9288 15.0086 15.5709 14.6432 16.2944C14.2779 17.0179 14.0628 17.808 14.0111 18.6169C13.9593 19.4258 14.0719 20.2368 14.342 21.001L2.992 21C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007L2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3L21.008 3ZM8 7C8.53043 7 9.03914 7.21071 9.41421 7.58579C9.78929 7.96086 10 8.46957 10 9C10 9.53043 9.78929 10.0391 9.41421 10.4142C9.03914 10.7893 8.53043 11 8 11C7.46957 11 6.96086 10.7893 6.58579 10.4142C6.21071 10.0391 6 9.53043 6 9C6 8.46957 6.21071 7.96086 6.58579 7.58579C6.96086 7.21071 7.46957 7 8 7Z" />
  </svg>
)
