import { createImage } from '@fable/hooks'

export const preloadImage = (
  src?: string | null,
  size: number = 640
): Promise<HTMLImageElement | null> => {
  return new Promise((resolve) => {
    if (!src) {
      resolve(null)
      return
    }

    const newImage = createImage(src, size)

    newImage.onload = () => {
      resolve(newImage)
    }
    newImage.onerror = () => {
      resolve(null)
    }
  })
}
