import {
  ShelfieContentItem,
  ShelfieObject,
  ShelfieOriginObject,
  ShelfieSwapObject,
  Trinket,
} from '../types'

export const updateObject = ({
  obj,
  items,
  trinket,
}: {
  obj: ShelfieObject
  items?: ShelfieContentItem[]
  trinket?: Trinket
}): ShelfieObject => {
  const updatedObject = obj

  if (!!items?.length) updatedObject.items = items
  if (!!trinket) updatedObject.trinket = trinket

  return { ...updatedObject }
}

export const replaceItemAtIdx = ({
  items,
  idx,
  newItem,
}: {
  items: ShelfieContentItem[]
  idx: number
  newItem: ShelfieContentItem
}) => {
  return [...items.slice(0, idx), newItem, ...items.slice(idx + 1)]
}

export const findObjectInShelf = (
  shelf: ShelfieObject[],
  objectId: string
): ShelfieObject | undefined => shelf.find((o) => o.id === objectId)

export const findObjectInShelfByItem = (
  shelf: ShelfieObject[],
  itemId: string
): ShelfieObject | undefined => {
  return shelf.find((o) => o.items?.some((item) => item.id === itemId))
}

export const updateShelf = (
  shelf: ShelfieObject[],
  updatedObjects: ShelfieObject[]
) =>
  shelf.map((o) => {
    const updatedObject = updatedObjects.find((uo) => uo.id === o.id)
    return updatedObject || o
  })

export const swapItemsInObject = ({
  shelf,
  origin,
  selectedItemId,
}: {
  shelf: ShelfieObject[]
  origin: ShelfieOriginObject
  selectedItemId?: string
}): ShelfieObject[] => {
  const items = origin.items
  const item = items.find((i) => i.id === selectedItemId) as ShelfieContentItem
  const prevItem = items[origin.itemIdx] as ShelfieContentItem

  const updatedItems = items.map((i) => {
    if (i.id === selectedItemId) return prevItem
    if (i.id === prevItem.id) return item
    return i
  })

  const updatedObject = updateObject({
    obj: origin.obj,
    items: updatedItems,
  })

  return updateShelf(shelf, [updatedObject])
}

export const updateTrinket = ({
  shelf,
  obj,
  trinket,
}: {
  shelf: ShelfieObject[]
  obj: ShelfieObject
  trinket: Trinket
}): ShelfieObject[] => {
  const updatedSourceObject = updateObject({ obj, trinket })
  return updateShelf(shelf, [updatedSourceObject])
}

export const getSwapObject = ({
  shelf,
  selectedItemId,
}: {
  shelf: ShelfieObject[]
  selectedItemId?: string
}): ShelfieSwapObject => {
  const obj = findObjectInShelfByItem(
    shelf,
    selectedItemId || ''
  ) as ShelfieObject
  const item = obj?.items?.find(
    (i) => i.id === selectedItemId
  ) as ShelfieContentItem
  const objItems = obj?.items || []
  const objItemIdx = objItems.findIndex((i) => i.id === selectedItemId)

  return { obj, item, items: objItems, itemIdx: objItemIdx }
}

export const isItemInSameObject = (
  items: ShelfieContentItem[],
  itemId?: string
) => !!items?.some((i) => i.id === itemId)

export const isItemInSameShelf = (shelf: ShelfieObject[], itemId?: string) =>
  !!shelf.find((o) => o.items?.some((item) => item.id === itemId))
