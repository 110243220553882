import { useCallback, useEffect, useRef } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useGeo } from '../../hooks'
import { WindowWithAJS } from './types'
import { setCookie } from '../../utils'
import { routes } from '../../app/internal/routes'
import { routes as socialRoutes } from '../../app/social/routes'
import { routes as webviewRoutes } from '../../app/webview/routes'
import { TRACKING_BLACKLIST } from '../../constants'

const matchesPath = (pathname: string) =>
  [
    matchPath(`${routes.genres}/:slug/image`, pathname),
    matchPath(`${routes.review}/:id/image`, pathname),
    pathname === socialRoutes.stats,
    pathname === socialRoutes.chatbot,
    pathname === socialRoutes.chatbotPreview,
    pathname === webviewRoutes.scoutPick,
    pathname === webviewRoutes.shelfie,
    pathname === webviewRoutes.eoyWrap,
    TRACKING_BLACKLIST(pathname),
  ].some((isTrue) => !!isTrue)

export const useConsent = () => {
  const { pathname } = useLocation()
  const bannerClosed = useRef(false)
  const { gdprCountry, geo } = useGeo()
  const blacklist = matchesPath(pathname)

  const wd = window as WindowWithAJS

  useEffect(() => {
    /**
     * @note
     * force hide cookie banner for blacklisted pages
     */
    if (blacklist) {
      setCookie('OptanonAlertBoxClosed', new Date().toISOString())
    }
  }, [blacklist])

  const closeBanner = useCallback(() => {
    if (!geo) return

    /**
     * @note
     * Only show the cookie banner for users in GDPR countries.
     */

    if (!bannerClosed.current && !gdprCountry) {
      wd.OneTrust.Close()
      bannerClosed.current = true
    }
  }, [wd.OneTrust, geo, gdprCountry])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (wd.OneTrust) {
        closeBanner()
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [closeBanner, wd.OneTrust])
}
