import { EditPanelTemplateTab, ShelfieLayoutMap } from '../types'

export const editorTemplateMap: ShelfieLayoutMap = {
  'five-item': {
    top: ['trinket', 'cover', 'two-item-spine', 'trinket'],
    bottom: ['cover', 'trinket', 'cover', 'trinket'],
  },
  'six-item': {
    top: ['trinket', 'cover', 'two-item-spine', 'trinket'],
    bottom: ['trinket', 'two-item-spine', 'cover', 'trinket'],
  },
  'seven-item': {
    top: ['trinket', 'cover', 'two-item-spine', 'trinket'],
    bottom: ['trinket', 'three-item-spine', 'cover', 'trinket'],
  },
  'eight-item': {
    top: ['cover', 'trinket', 'three-item-spine', 'trinket'],
    bottom: ['trinket', 'three-item-spine', 'cover', 'trinket'],
  },
}

export const editPanelTemplateTabs: EditPanelTemplateTab[] = [
  { id: 'text', label: 'Text', icon: 't-box' },
  { id: 'shelf', label: 'Shelf', icon: 'file-list' },
  { id: 'wallpaper', label: 'Wallpaper', icon: 'pantone' },
]
