import { getParameterByName } from '@fable/utils'

export const platformParam = getParameterByName('platform')
export const isAndroid = !!platformParam && platformParam === 'android'

export const appendModeCors = (imageUrl: string): string => {
  return imageUrl.includes('?')
    ? `${imageUrl}&mode=cors`
    : `${imageUrl}?mode=cors`
}
