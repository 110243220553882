import {
  editorTemplateMap,
  fontList,
  shelfList,
  trinketList,
  wallpaperList,
} from '../constants'
import { ShelfieEditorStatus } from '../ctx/editor/types'
import {
  ShelfieContentItem,
  ShelfieFontType,
  ShelfieLayoutType,
  ShelfieObject,
  ShelfieObjectType,
  ShelfieShelfType,
  ShelfieWallpaperType,
  Trinket,
} from '../types'
import { TrinketKey } from '../types'
import { v4 as uuid } from 'uuid'

export const getTemplateLayout = (type: ShelfieLayoutType) => {
  return editorTemplateMap[type]
}

export const getTrinket = (trinket: TrinketKey) => {
  const item = trinketList.find((t) => t.id === trinket)

  if (!item) {
    const randomIndex = Math.floor(Math.random() * trinketList.length)
    return trinketList[randomIndex]
  }

  return item
}

export const getDefaultLayoutType = (
  contentItems: ShelfieContentItem[]
): ShelfieLayoutType => {
  switch (contentItems.length) {
    case 5:
      return 'five-item'
    case 6:
      return 'six-item'
    case 7:
      return 'seven-item'
    case 8:
      return 'eight-item'
    default:
      return 'five-item'
  }
}

export const getWallpaper = (types: ShelfieWallpaperType[]) => {
  const randomIndex = Math.floor(Math.random() * types.length)
  const randomType = types[randomIndex]
  const wallpaper = wallpaperList.find((w) => w.id === randomType)
  return wallpaper || wallpaperList[0]
}

export const getShelf = (type: ShelfieShelfType) => {
  const shelf = shelfList.find((s) => s.id === type)
  return shelf || shelfList[0]
}

export const getFont = (type: ShelfieFontType) => {
  const font = fontList.find((f) => f.id === type)
  return font || fontList[0]
}

export const mapContentToObjects = ({
  types,
  contentItems,
  trinkets,
}: {
  types: ShelfieObjectType[]
  contentItems: ShelfieContentItem[]
  trinkets: Trinket[]
}) => {
  return types.map((type) => {
    const { items, trinket } = getShelfieObject({
      contentItems,
      type,
      trinkets,
    })

    const item: ShelfieObject = {
      id: uuid(),
      type,
      items,
      trinket,
    }

    return item
  })
}

const getShelfieObject = ({
  contentItems,
  type,
  trinkets = [],
}: {
  contentItems: ShelfieContentItem[]
  type: ShelfieObjectType
  trinkets?: Trinket[] // Marking as optional
}): Partial<ShelfieObject> => {
  let trinket: Trinket | undefined
  let selectedItems: ShelfieContentItem[] = []

  switch (type) {
    case 'cover':
      selectedItems = contentItems.splice(0, 1)
      break
    case 'trinket':
      trinket = trinkets.length > 0 ? trinkets.splice(0, 1)[0] : undefined
      return { trinket }
    case 'two-item-spine':
      selectedItems = contentItems.splice(0, 2)
      break
    case 'three-item-spine':
      selectedItems = contentItems.splice(0, 3)
      break
  }

  return {
    items: selectedItems.length > 0 ? selectedItems : undefined,
    trinket,
  }
}

export const isEditorPage = (status: ShelfieEditorStatus) =>
  status === 'editing' || status === 'anim'
