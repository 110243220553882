import {
  AppleIcon,
  ArrowDownCircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BookIcon,
  BookmarkAddIcon,
  BookmarkIcon,
  BookmarkOutlineIcon,
  CaretDownIcon,
  CartIcon,
  ChatIcon,
  CheckIcon,
  ChevronIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronSlimIcon,
  CloseCircleIcon,
  CloseIcon,
  CopyIcon,
  DollarIcon,
  DownloadIcon,
  EditAltIcon,
  EditIcon,
  EyeIcon,
  EyeOffIcon,
  FacebookIcon,
  FacebookOutlineIcon,
  FileListIcon,
  FlashlightIcon,
  ForbidIcon,
  GiftIcon,
  GoodreadsIcon,
  GoogleIcon,
  HeartIcon,
  HistoryIcon,
  ImageAddIcon,
  InfoIcon,
  InfoIconReverse,
  InstagramIcon,
  LinkedInIcon,
  LockIcon,
  MembersIcon,
  MenuIcon,
  MicrosoftIcon,
  MoreIcon,
  PantoneIcon,
  ParentIcon,
  PencilIcon,
  PersonBadgeIcon,
  PinIcon,
  PinOutlineIcon,
  PlayCircleIcon,
  PlayIcon,
  PriceTagIcon,
  QuoteIcon,
  RefreshIcon,
  RepeatIcon,
  SearchIcon,
  SendIcon,
  SettingsIcon,
  ShareIcon,
  SparkleIcon,
  StarIcon,
  StarSolidIcon,
  StopIcon,
  TBoxIcon,
  ThumbsDownIcon,
  ThumbsDownOutlineIcon,
  ThumbsUpIcon,
  ThumbsUpOutlineIcon,
  TickIcon,
  TikTokIcon,
  TrashIcon,
  TrophyIcon,
  TrophyOutlineIcon,
  TwitterIcon,
  UserHeartIcon,
  UserIcon,
  UserOutlineIcon,
  WebsiteIcon,
  XIcon,
  YouTubeIcon,
  YouTubeOutlineIcon,
} from './variants'
import { IconProps } from './types'

export const getIcon = ({ type, ...nativeProps }: IconProps) => {
  let SVG: React.ElementType

  switch (type) {
    case 'apple':
      SVG = AppleIcon
      break
    case 'google':
      SVG = GoogleIcon
      break
    case 'microsoft':
      SVG = MicrosoftIcon
      break
    case 'arrow-down-circle':
      SVG = ArrowDownCircleIcon
      break
    case 'arrow-left':
      SVG = ArrowLeftIcon
      break
    case 'arrow-right':
      SVG = ArrowRightIcon
      break
    case 'book':
      SVG = BookIcon
      break
    case 'bookmark-add':
      SVG = BookmarkAddIcon
      break
    case 'bookmark-outline':
      SVG = BookmarkOutlineIcon
      break
    case 'bookmark':
      SVG = BookmarkIcon
      break
    case 'caret-down':
      SVG = CaretDownIcon
      break
    case 'cart':
      SVG = CartIcon
      break
    case 'chat':
      SVG = ChatIcon
      break
    case 'check':
      SVG = CheckIcon
      break
    case 'chevron':
      SVG = ChevronIcon
      break
    case 'chevron-left':
      SVG = ChevronLeftIcon
      break
    case 'chevron-slim':
      SVG = ChevronSlimIcon
      break
    case 'chevron-right':
      SVG = ChevronRightIcon
      break
    case 'close':
      SVG = CloseIcon
      break
    case 'close-circle':
      SVG = CloseCircleIcon
      break
    case 'copy':
      SVG = CopyIcon
      break
    case 'dollar':
      SVG = DollarIcon
      break
    case 'download':
      SVG = DownloadIcon
      break
    case 'edit':
      SVG = EditIcon
      break
    case 'edit-alt':
      SVG = EditAltIcon
      break
    case 'eye':
      SVG = EyeIcon
      break
    case 'eye-off':
      SVG = EyeOffIcon
      break
    case 'file-list':
      SVG = FileListIcon
      break
    case 'forbid':
      SVG = ForbidIcon
      break
    case 'flashlight':
      SVG = FlashlightIcon
      break
    case 'gift':
      SVG = GiftIcon
      break
    case 'heart':
      SVG = HeartIcon
      break
    case 'history':
      SVG = HistoryIcon
      break
    case 'image-add':
      SVG = ImageAddIcon
      break
    case 'info':
      SVG = InfoIcon
      break
    case 'info-reverse':
      SVG = InfoIconReverse
      break
    case 'lock':
      SVG = LockIcon
      break
    case 'members':
      SVG = MembersIcon
      break
    case 'menu':
      SVG = MenuIcon
      break
    case 'more':
      SVG = MoreIcon
      break
    case 'parent':
      SVG = ParentIcon
      break
    case 'pin':
      SVG = PinIcon
      break
    case 'pin-outline':
      SVG = PinOutlineIcon
      break
    case 'pantone':
      SVG = PantoneIcon
      break
    case 'person-badge':
      SVG = PersonBadgeIcon
      break
    case 'play':
      SVG = PlayIcon
      break
    case 'play-circle':
      SVG = PlayCircleIcon
      break
    case 'price-tag':
      SVG = PriceTagIcon
      break
    case 'pencil':
      SVG = PencilIcon
      break
    case 'quote':
      SVG = QuoteIcon
      break
    case 'refresh':
      SVG = RefreshIcon
      break
    case 'repeat':
      SVG = RepeatIcon
      break
    case 'send':
      SVG = SendIcon
      break
    case 'search':
      SVG = SearchIcon
      break
    case 'settings':
      SVG = SettingsIcon
      break
    case 'share':
      SVG = ShareIcon
      break
    case 'sparkle':
      SVG = SparkleIcon
      break
    case 'star':
      SVG = StarIcon
      break
    case 'star-solid':
      SVG = StarSolidIcon
      break
    case 'stop':
      SVG = StopIcon
      break
    case 'thumbs-down':
      SVG = ThumbsDownIcon
      break
    case 'thumbs-down-outline':
      SVG = ThumbsDownOutlineIcon
      break
    case 'thumbs-up':
      SVG = ThumbsUpIcon
      break
    case 'thumbs-up-outline':
      SVG = ThumbsUpOutlineIcon
      break
    case 'tick':
      SVG = TickIcon
      break
    case 'trash':
      SVG = TrashIcon
      break
    case 'trophy':
      SVG = TrophyIcon
      break
    case 'trophy-outline':
      SVG = TrophyOutlineIcon
      break
    case 'goodreads':
      SVG = GoodreadsIcon
      break
    case 'facebook':
      SVG = FacebookIcon
      break
    case 'facebook-outline':
      SVG = FacebookOutlineIcon
      break
    case 't-box':
      SVG = TBoxIcon
      break
    case 'twitter':
      SVG = TwitterIcon
      break
    case 'user-heart':
      SVG = UserHeartIcon
      break
    case 'youtube':
      SVG = YouTubeIcon
      break
    case 'youtube-outline':
      SVG = YouTubeOutlineIcon
      break
    case 'instagram':
      SVG = InstagramIcon
      break
    case 'tiktok':
      SVG = TikTokIcon
      break
    case 'linkedin':
      SVG = LinkedInIcon
      break
    case 'user-outline':
      SVG = UserOutlineIcon
      break
    case 'user':
      SVG = UserIcon
      break
    case 'website':
      SVG = WebsiteIcon
      break
    case 'x':
      SVG = XIcon
      break
    default:
      SVG = EditIcon
  }

  return <SVG {...nativeProps} />
}
