import { getBucketImage } from 'app/webview/utils'
import { ShelfieWallpaper } from '../types'

const getImage = (name: string) =>
  getBucketImage(`/shelfie/wallpapers/${name}.webp?mode=cors`, {
    source: 'cdn',
  })

export const wallpaperList: ShelfieWallpaper[] = [
  {
    id: 'generic-wallpaper-three',
    name: 'Generic Wallpaper',
    image: getImage('generic-wallpaper-three'),
  },
  {
    id: 'generic-wallpaper-one',
    name: 'Generic Wallpaper',
    image: getImage('generic-wallpaper-one'),
  },
  {
    id: 'generic-wallpaper-two',
    name: 'Generic Wallpaper',
    image: getImage('generic-wallpaper-two'),
  },
  {
    id: 'young-adult-wallpaper-one',
    name: 'Young Adult Wallpaper',
    image: getImage('young-adult-wallpaper-one'),
  },
  {
    id: 'fantasy-wallpaper-one',
    name: 'Fantasy Wallpaper',
    image: getImage('fantasy-wallpaper-one'),
  },
  {
    id: 'fantasy-wallpaper-two',
    name: 'Fantasy Wallpaper',
    image: getImage('fantasy-wallpaper-two'),
  },
  {
    id: 'nature-wallpaper-one',
    name: 'Nature Wallpaper',
    image: getImage('nature-wallpaper-one'),
  },
  {
    id: 'romance-wallpaper-two',
    name: 'Romance Wallpaper',
    image: getImage('romance-wallpaper-two'),
  },
  {
    id: 'nature-wallpaper-two',
    name: 'Nature Wallpaper',
    image: getImage('nature-wallpaper-two'),
  },
  {
    id: 'historical-wallpaper-one',
    name: 'Historical Wallpaper',
    image: getImage('historical-wallpaper-one'),
  },
  {
    id: 'gore-wallpaper-two',
    name: 'Gore Wallpaper',
    image: getImage('gore-wallpaper-two'),
  },
  {
    id: 'gore-wallpaper-one',
    name: 'Gore Wallpaper',
    image: getImage('gore-wallpaper-one'),
  },
  {
    id: 'dark-wallpaper-one',
    name: 'Dark Wallpaper',
    image: getImage('dark-wallpaper-one'),
  },
  {
    id: 'sci-fi-wallpaper-two',
    name: 'Sci-Fi Wallpaper',
    image: getImage('sci-fi-wallpaper-two'),
  },
  {
    id: 'sci-fi-wallpaper-one',
    name: 'Sci-Fi Wallpaper',
    image: getImage('sci-fi-wallpaper-one'),
  },
  {
    id: 'romance-wallpaper-one',
    name: 'Romance Wallpaper',
    image: getImage('romance-wallpaper-one'),
  },
  {
    id: 'manga-wallpaper-two',
    name: 'Graphic/Manga Wallpaper',
    image: getImage('manga-wallpaper-two'),
  },
  {
    id: 'manga-wallpaper-one',
    name: 'Graphic/Manga Wallpaper',
    image: getImage('manga-wallpaper-one'),
  },
]
