import { ShelfieShelf } from '../types'

import lightWood from '../assets/shelves/light-wood-shelf.webp'
import darkWood from '../assets/shelves/dark-wood-shelf.webp'
import white from '../assets/shelves/white-shelf.webp'
import black from '../assets/shelves/black-shelf.webp'

export const shelfList: ShelfieShelf[] = [
  {
    id: 'light-wood',
    name: 'Light Wood',
    image: lightWood,
  },
  {
    id: 'dark-wood',
    name: 'Dark Wood',
    image: darkWood,
  },
  {
    id: 'white',
    name: 'White',
    image: white,
  },
  {
    id: 'black',
    name: 'Black',
    image: black,
  },
]
