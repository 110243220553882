import { get } from '../fable'

const getBaseStats = async ({
  userId,
  year,
}: {
  userId: string
  year: number
}) => await get(`users/${userId}/stats/annual_stats?year=${year}`)

export const stats = {
  getBaseStats,
}
