import { useState } from 'react'
import { css, cx } from '@fable/theme'
import { FlexBox } from '../'
import ReviewStar from './ReviewStar'
import ReviewStarsHiddenButton from './ReviewStarsHiddenButton'
import ReviewStarV2 from './ReviewStarV2'

// star images are absolutely positioned above two buttons aligned side-by-side

interface ReviewStarsPropsBase {
  /** @param {string} className - to add additional styles */
  className?: string
  /** @param {string} starStyles - to add additional styles to the star */
  starStyles?: string
  /** @param {string} color - sets color of star fill and outline */
  color: string
  /** @param {number} rating - to show star rating (this is a controlled component) */
  rating: number
  /** @param {number} size - sets the width and height of a star */
  size?: number
  /** @param {number} spacing - sets the margin between stars */
  spacing?: number
  /** @param {boolean} v2 - sets the version of the stars */
  v2?: boolean
}

interface ReviewStarsPropsReadWrite extends ReviewStarsPropsBase {
  /** @param {boolean} readOnly - disables interaction */
  readOnly?: false
  /** @param {(num: number) => void} onClickStar - when readOnly is false or undefined */
  onClickStar: (num: number) => void
}

interface ReviewStarsPropsReadOnly extends ReviewStarsPropsBase {
  readOnly: true
  onClickStar?: never
}

type ReviewStarsProps = ReviewStarsPropsReadOnly | ReviewStarsPropsReadWrite

const ReviewStars = (props: ReviewStarsProps) => {
  const {
    /** @param {string} color - sets color of star fill and outline */
    color,
    /** @param {number} rating - to show star rating (this is a controlled component) */
    rating,
    /** @param {boolean} readOnly - disables interaction */
    readOnly,
    /** @param {(rating: number) => void} onClickStar - only when readOnly is false or undefined */
    onClickStar,
    /** @param {number} size - size of star in pixels */
    size,
    /** @param {number} spacing - spacing between stars in pixels */
    spacing,
  } = props as ReviewStarsProps
  const [hoveredStar, setHoveredStar] = useState(0)

  /**
   * @note
   * readOnly stars can be small but the size set in the design spec (16px) is way too small
   * for hovering and clicking half stars
   */
  const defaultStarSize = readOnly ? 16 : 24
  const starSize = size || defaultStarSize
  const defaultSpacing = readOnly ? 2 : 4
  const starSpacing = spacing || defaultSpacing

  const determineQuarter = (num: number) => {
    if (hoveredStar > 0) {
      return hoveredStar > num - 1 && hoveredStar <= num - 0.75
    }

    if (rating) {
      return rating % 1 > 0 && rating % 1 <= 0.25 && num === Math.ceil(rating)
    }

    return false
  }

  const determineHalf = (num: number) => {
    if (hoveredStar > 0) {
      return hoveredStar === num - 0.5
    }

    if (rating) {
      if (props.v2) {
        return (
          rating % 1 > 0.25 && rating % 1 <= 0.5 && num === Math.ceil(rating)
        )
      } else {
        return rating % 1 > 0 && num === Math.ceil(rating)
      }
    }

    return false
  }

  const determineThreeQuarter = (num: number) => {
    if (hoveredStar > 0) {
      return hoveredStar > num - 0.25 && hoveredStar <= num
    }

    if (rating) {
      return rating % 1 > 0.5 && rating % 1 <= 0.75 && num === Math.ceil(rating)
    }

    return false
  }

  const determineEmpty = (num: number) => {
    if (hoveredStar > 0) {
      return hoveredStar < num - 0.5
    }

    if (rating) {
      return num > Math.ceil(rating)
    }

    return true
  }

  return (
    <FlexBox
      data-testid="reviewStars"
      alignItems="center"
      onMouseLeave={readOnly ? undefined : () => setHoveredStar(0)}
      className={cx(
        css`
          gap: ${starSpacing}px;
          svg {
            width: 100%;
            height: 100%;
          }
        `,
        props.className
      )}
    >
      {[1, 2, 3, 4, 5].map((num) => {
        const sharedPropsForHiddenButton = {
          readOnly,
          hoveredStar,
          setHoveredStar,
          onClickStar,
        }

        return (
          <div
            key={num}
            className={cx(
              css`
                position: relative;
                flex: 0 0 auto;
                width: ${starSize}px;
                height: ${starSize}px;
              `,
              props.starStyles
            )}
          >
            <ReviewStarsHiddenButton
              valueWhenHovered={num - 0.5}
              left
              {...sharedPropsForHiddenButton}
            />
            {props.v2 ? (
              <ReviewStarV2
                color={color}
                isEmpty={determineEmpty(num)}
                isQuarter={determineQuarter(num)}
                isHalf={determineHalf(num)}
                isThreeQuarter={determineThreeQuarter(num)}
              />
            ) : (
              <ReviewStar
                color={color}
                isHalf={determineHalf(num)}
                isEmpty={determineEmpty(num)}
              />
            )}
            <ReviewStarsHiddenButton
              valueWhenHovered={num}
              right
              {...sharedPropsForHiddenButton}
            />
          </div>
        )
      })}
    </FlexBox>
  )
}

export default ReviewStars
