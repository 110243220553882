const ReviewStarV2 = ({
  color,
  isEmpty,
  isQuarter,
  isHalf,
  isThreeQuarter,
  className = '',
}: {
  className?: string
  color: string
  isEmpty: boolean
  isQuarter: boolean
  isHalf: boolean
  isThreeQuarter: boolean
}) => {
  if (isEmpty) {
    return (
      <svg
        data-testid="starEmpty"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.58 26.23"
        fill={color}
      >
        <path d="m27.58,10.02l-9.7-1.15L13.79,0l-4.09,8.87L0,10.02l7.17,6.63-1.9,9.58,8.52-4.77,8.52,4.77-1.9-9.58,7.17-6.63Zm-13.79,8.67l-5.13,2.87,1.15-5.77-4.32-3.99,5.84-.69,2.46-5.34,2.46,5.34,5.84.69-4.32,3.99,1.15,5.77-5.13-2.87Z" />
      </svg>
    )
  }
  if (isQuarter) {
    return (
      <svg
        data-testid="starQuarter"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.58 26.23"
        fill={color}
      >
        <path d="m27.58,10.02l-9.7-1.15L13.79,0l-2.46,5.34-1.63,3.53L0,10.02l7.17,6.63-1.9,9.58,6.06-3.39,2.46-1.38,8.52,4.77-1.9-9.58,7.17-6.63Zm-13.79,8.67l-2.46,1.38v-8.96l2.46-5.34,2.46,5.34,5.84.69-4.31,4,1.14,5.76-5.13-2.87Z" />
      </svg>
    )
  }
  if (isHalf) {
    return (
      <svg
        data-testid="starHalf"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.58 26.23"
        fill={color}
      >
        <path d="m27.58,10.02l-9.7-1.15L13.79,0l-4.09,8.87L0,10.02l7.17,6.63-1.9,9.58,8.52-4.77,8.52,4.77-1.9-9.58,7.17-6.63Zm-13.79,8.67V5.77l2.46,5.34,5.84.69-4.31,4,1.14,5.76-5.13-2.87Z" />
      </svg>
    )
  }
  if (isThreeQuarter) {
    return (
      <svg
        data-testid="starThreeQuarter"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.57 26.23"
        fill={color}
      >
        <path d="m27.57,10.02l-9.7-1.15-1.62-3.51L13.79.01l-4.09,8.86h0L0,10.02l7.16,6.63h.01l-1.9,9.57,8.51-4.76,2.47,1.38,6.06,3.39-1.91-9.58,7.17-6.63Zm-11.32,10.05v-8.96l5.84.69-4.32,4,1.14,5.76-2.66-1.49Z" />
      </svg>
    )
  }
  return (
    <svg
      data-testid="starFull"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.58 26.23"
      fill={color}
    >
      <polygon points="20.41 16.65 22.31 26.23 13.79 21.46 5.27 26.23 7.17 16.65 0 10.02 9.7 8.87 13.79 0 17.88 8.87 27.58 10.02 20.41 16.65" />
    </svg>
  )
}

export default ReviewStarV2
