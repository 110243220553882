import { trackEvent } from 'segment_analytics/events'

const defaultEventProps = {
  platform: 'web',
}

const start = () => {
  return trackEvent('wrap_start', defaultEventProps)
}

const modalViewed = () => {
  return trackEvent('wrap_modal_viewed', defaultEventProps)
}

const finishedListDeeplink = () => {
  return trackEvent('wrap_finished_list_deeplink', defaultEventProps)
}

const polaroidPreSetup = () => {
  return trackEvent('wrap_polaroid_pre_setup', defaultEventProps)
}

const polaroidSetupSkip = () => {
  return trackEvent('wrap_polaroid_setup_skip', defaultEventProps)
}

const polaroidSetup = ({ ...props }: { seasons: number }) => {
  const properties = {
    ...props,
    ...defaultEventProps,
  }

  return trackEvent('wrap_polaroid_setup', properties)
}

const seasonLoading = () => {
  return trackEvent('wrap_season_loading', defaultEventProps)
}

export const eoyWrap = {
  start,
  modalViewed,
  finishedListDeeplink,
  polaroidPreSetup,
  polaroidSetupSkip,
  polaroidSetup,
  seasonLoading,
}
