import { trackEvent } from 'segment_analytics/events'

const defaultEventProps = {
  platform: 'web',
}

const shelfieProps = {
  starting_from: 'web_shelfie',
  ...defaultEventProps,
}

const bookSelectionStart = () => {
  return trackEvent('shelfie_book_selection_start', defaultEventProps)
}

const bookSearchOpened = () => {
  return trackEvent('bookSearchOpened', shelfieProps)
}

const bookSearchDismissed = () => {
  return trackEvent('bookSearchDismissed', shelfieProps)
}

const bookSearchTappedBook = () => {
  return trackEvent('bookSearchTappedBook', shelfieProps)
}

const showSearchOpened = () => {
  return trackEvent('show_search_opened', shelfieProps)
}

const showSearchDismissed = () => {
  return trackEvent('show_search_dismissed', shelfieProps)
}

const showSearchTappedShow = () => {
  return trackEvent('show_search_tapped_show', shelfieProps)
}

const customize = () => {
  return trackEvent('shelfie_customize', defaultEventProps)
}

const exitWarning = () => {
  return trackEvent('shelfie_exit_warning', defaultEventProps)
}

const exitCanceled = () => {
  return trackEvent('shelfie_exit_canceled', defaultEventProps)
}

const closed = () => {
  return trackEvent('shelfie_closed', defaultEventProps)
}

const backButtonWarning = () => {
  return trackEvent('shelfie_back_button_warning', defaultEventProps)
}

const backButtonCanceled = () => {
  return trackEvent('shelfie_back_button_canceled', defaultEventProps)
}

const backButton = () => {
  return trackEvent('shelfie_back_button', defaultEventProps)
}

const loading = ({ ...props }: { num_books: number }) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }
  return trackEvent('shelfie_loading', properties)
}

const loaded = ({
  ...props
}: {
  genre: string
  theme: string
  num_books: number
}) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }
  return trackEvent('shelfie_loaded', properties)
}

const fontCustomized = () => {
  return trackEvent('shelfie_font_customized', defaultEventProps)
}

const shelfCustomized = () => {
  return trackEvent('shelfie_shelf_customized', defaultEventProps)
}

const wallpaperCustomized = () => {
  return trackEvent('shelfie_wallpaper_customized', defaultEventProps)
}

const objectCustomized = ({ ...props }: { shelfie_object: string }) => {
  const properties = {
    ...defaultEventProps,
    ...props,
  }
  return trackEvent('shelfie_object_customized', properties)
}

const bookCustomized = () => {
  return trackEvent('shelfie_book_customized', defaultEventProps)
}

const downloaded = () => {
  return trackEvent('shelfie_downloaded', defaultEventProps)
}

const readerSummaryFailed = () => {
  return trackEvent('shelfie_reader_summary_failed', defaultEventProps)
}

const shareViewed = () => {
  return trackEvent('shelfie_share_viewed', defaultEventProps)
}

const igStoryTapped = () => {
  return trackEvent('shelfie_ig_story_tapped', defaultEventProps)
}

const igStorySuccess = () => {
  return trackEvent('shelfie_ig_story_success', defaultEventProps)
}

const tiktokTapped = () => {
  return trackEvent('shelfie_tiktok_tapped', defaultEventProps)
}

const tiktokSuccess = () => {
  return trackEvent('shelfie_tiktok_success', defaultEventProps)
}

export const shelfie = {
  bookSelectionStart,
  bookSearchOpened,
  bookSearchDismissed,
  bookSearchTappedBook,
  showSearchOpened,
  showSearchDismissed,
  showSearchTappedShow,
  customize,
  exitWarning,
  exitCanceled,
  closed,
  backButtonWarning,
  backButtonCanceled,
  backButton,
  objectCustomized,
  bookCustomized,
  downloaded,
  readerSummaryFailed,
  shareViewed,
  igStoryTapped,
  igStorySuccess,
  tiktokTapped,
  tiktokSuccess,
  loading,
  loaded,
  fontCustomized,
  shelfCustomized,
  wallpaperCustomized,
}
