import { createImage } from '@fable/hooks'

export const getBucketImage = (
  src: string,
  options?: { source: 'web' | 'cdn' }
) => {
  const source = options?.source || 'web'
  let baseUrl = 'https://storage.googleapis.com/fable-web-cdn-prod'

  if (source === 'cdn') {
    baseUrl = 'https://img.fablecdn.net/images/cdn.fable.co'
  }

  return `${baseUrl}${src}`
}

export const preloadImage = (
  src?: string | null,
  size: number = 640
): Promise<HTMLImageElement | null> => {
  return new Promise((resolve) => {
    if (!src) {
      resolve(null)
      return
    }

    const newImage = createImage(src, size)

    newImage.onload = () => {
      resolve(newImage)
    }
    newImage.onerror = () => {
      resolve(null)
    }
  })
}
