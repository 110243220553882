import { css } from '@fable/theme'
import { ShelfieFont } from '../types'

const baseStyles = css`
  line-height: 0.9;
  font-style: normal;
`

export const fontList: ShelfieFont[] = [
  {
    id: 'bokor',
    name: 'Bokor',
    styles: css`
      font-family: 'Bokor', system-ui;
      font-weight: 400;
      ${baseStyles};
    `,
  },
  {
    id: 'heldane',
    name: 'Heldane',
    styles: css`
      font-family: 'Heldane Display', serif;
      font-weight: 700;
      ${baseStyles};
    `,
  },
  {
    id: 'faster-one',
    name: 'Faster One',
    styles: css`
      font-family: 'Faster One', system-ui;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: -0.05em;
      ${baseStyles};
    `,
  },
  {
    id: 'cormorant-unicase',
    name: 'Cormorant Unicase',
    styles: css`
      font-family: 'Cormorant Unicase', serif;
      font-weight: 400;
      ${baseStyles};
    `,
  },
  {
    id: 'sarina',
    name: 'Sarina',
    styles: css`
      font-family: 'Sarina', cursive;
      font-weight: 400;
      ${baseStyles};
    `,
  },
  {
    id: 'bubblegum',
    name: 'BubbleGum',
    styles: css`
      font-family: 'BubbleGum', system-ui;
      font-weight: 400;
      ${baseStyles};
    `,
  },
  {
    id: 'lacquer',
    name: 'Lacquer',
    styles: css`
      font-family: 'Lacquer', system-ui;
      font-weight: 400;
      ${baseStyles};
    `,
  },
  {
    id: 'protest-revolution',
    name: 'Protest Revolution',
    styles: css`
      font-family: 'Protest Revolution', sans-serif;
      font-weight: 400;
      ${baseStyles};
    `,
  },
  {
    id: 'quintessential',
    name: 'Quintessential',
    styles: css`
      font-family: 'Quintessential', serif;
      font-weight: 400;
      text-transform: uppercase;
      ${baseStyles};
    `,
  },
]
